import Papa from 'papaparse';
import React, {useState, useEffect } from 'react';
import './App.css';
import Favicon from "react-favicon";

const SHEET="https://docs.google.com/spreadsheets/d/e/2PACX-1vRPZft_7o38iIQQ-_6yfKd2WexId_UM0IFx7FxDLh8Ne1CSOl-G1ubav7Fgk2nnmEaTq6E-DTmPp5OX/pub?gid=0&single=true&output=csv";

function isDateValid(dateStr) {
    return !isNaN(new Date(dateStr));
}

function GetTalk(talk,showzoom) {
    return (
	<div class="App-talk">
	    { talk.Photolink ?
	      <div class="photo">
		  <img src={ talk.Photolink } alt={ "Photo " + talk.Speaker } />
	      </div>:
	      ("")
	    }
	    {  isDateValid(talk.Date) ?
	       (<button type="button" class="btn">{ talk.Date }</button>)
	       : (<button type="button" class="btn">Date not set yet.</button>)
	    }
	    { talk.Time?
	      (<button type="button" class="btn">{ talk.Time }</button>)
	      : ("")
	    }
	    {  talk.SpeakerWebpage ?
	       (<div class="speaker"><a href={talk.SpeakerWebpage}>{talk.Speaker}</a>
		    { talk.Affiliation ?
		      (<i>{" (" + talk.Affiliation + ")"}</i>) : ("")
		    }
		</div>) :
	       (<div class="talktitle">{talk.Speaker}
		    { talk.Affiliation ?
		      (<i>{" (" + talk.Affiliation + ")"}</i>) : ("")
		    }
		</div>)
	    }
	    <div class="talktitle">
		{ talk.TalkTitle ?
		  (talk.TalkTitle) :
		  ("Talk title not announced yet")
		}
	    </div>
	    { talk.Room ?
	      (<div class="room"><b>Room: </b>{ talk.Room }</div>) :
	      ("")
	    }
	    { talk.Host ?
	      (<div class="host"><b>Host: </b>{ talk.Host }</div>) :
	      ("")
	    }
	    { (talk.Zoomlink && showzoom) ?
	      (<div class="zoomlink">
		   <b>Zoomlink: </b>
		   <a href={talk.Zoomlink}>{talk.Zoomlink}</a>
	       </div>) :
	      ("")
	    }
	    { talk.Abstract ?
	      (<div class="abstract"><b>Abstract: </b>{talk.Abstract}</div>):
	      ("")
	    }
	    { talk.BIO ?
	      (<div class="speakerbio"><b>Speaker Bio: </b>{talk.BIO}</div>):
	      ("")
	    }
	</div>
    );
}

function CompareDates(talka,talkb) {
    let a = talka.Date;
    let b = talkb.Date;
    a = isDateValid(a) ? new Date(a) : new Date('2300-01-01');
    b = isDateValid(b) ? new Date(b) : new Date('2300-01-01');

    return a - b;
}

function ReverseOrder(order) {
    return (a,b) => order(b,a);
}


function curChicagoTime() {
    // offset Chicago
    // var offset = -5.0 + 12.0

    // create Date object for current location
    var d = new Date();

    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    // var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // // create new Date object for different city
    // // using supplied offset
    // var nd = new Date(utc + (3600000*offset));

    // return time as a string
    return d.getTime()
}

function GetTbl() {
    const [parsedCsvData, setParsedCsvData] = useState([]);
    useEffect(() => {
	grabData()
    }, [])

    const grabData = () => Papa.parse(SHEET, {
        download: true,
        header: true,
        complete: function(results) {
            setParsedCsvData(results.data);
        }
    });

    const curdate = curChicagoTime(); // Date.now();

    let upcoming = [...parsedCsvData]
	.filter((talk) => isDateValid(talk.Date) && Date.parse(talk.Date) >= curdate)
	.sort(CompareDates);

    let past = [...parsedCsvData]
	.filter((talk) => isDateValid(talk.Date) && Date.parse(talk.Date) < curdate)
	.sort(ReverseOrder(CompareDates))

    let planned = [...parsedCsvData]
	.filter((talk) => !isDateValid(talk.Date));

    return (
	<div>
	    <h1>Upcoming Seminars</h1>
	    <div class="App-box">
		{upcoming
		 .map((talk, index) => GetTalk(talk,true))}
	    </div>
	    <h1>Past Seminars</h1>
	    <div class="App-box">
		{past
		 .map((talk, index) => GetTalk(talk,true))}
	    </div>
	    <h1>Planned Seminars</h1>
	    <div class="App-box">
		{planned
		 .map((talk,index) => GetTalk(talk,false))}
	    </div>
	</div>
    );
}

function App() {
    return (
        <div className="App">
	    <head>
		<title>UIC Virtual Data Seminar Series</title>
	    </head>
	    <Favicon url="https://news.law.uic.edu/wp-content/uploads/2020/12/uic-favicon-300x300.png"/>
	    <h1>UIC Data Labs Seminars</h1>
	    <div className="overview">
		<p>This is the webpage for the <b>University of Illinois Chicago Data Labs Seminars</b>.
		    Talks are virtual over zoom.
		Everybody at UIC is welcome to attend these talks on cutting edge research in data management, data mining, databases, and data science.</p>

		<p>The seminars are organized by the following faculty (in alphabetical order):</p>
		<ul>
		    <li><a href="https://www.cs.uic.edu/~asudeh/">Abolfazl Asudeh</a></li>
		    <li><a href="https://www.cs.uic.edu/~bglavic/dbgroup/members/bglavic.html">Boris Glavic</a></li>
		    <li><a href="https://sites.google.com/view/stavros-sintos">Stavros Sintos</a></li>
		    <li><a href="https://fmiranda.me/">Fabio Miranda</a></li>
		    <li><a href="https://www.cs.uic.edu/~elena/">Elena Zheleva</a></li>
		    <li><a href="https://souravmedya.github.io/">Sourav Medya</a></li>
		    <li><a href="https://cs.uic.edu/profiles/cheng-lu/">Lu Cheng</a></li>
		</ul>

		<p>If you are interested in attending (some) of the talks, you can register for the mailing list on <a href="https://groups.google.com/u/1/g/uic-data-labs-seminars">google groups</a>.</p>
	    </div>
	    <br/>
	    <div>
		{GetTbl()}
	    </div>
	</div>
    );
}





export default App;
